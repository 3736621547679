import { useEffect, useRef } from 'react'

/**
 * useEffectOnceWhen hook
 * https://github.com/imbhargav5/rooks/blob/main/src/hooks/useEffectOnceWhen.ts
 *
 * It fires a callback once when a condition is true or become true.
 * Fires the callback at most one time.
 *
 * @param callback The callback to fire
 * @param when The condition which needs to be true
 */
function useEffectOnceWhen(callback, when = true) {
  const hasRunOnceRef = useRef(false)

  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    if (when && !hasRunOnceRef.current) {
      setTimeout(callbackRef.current, 0)
      hasRunOnceRef.current = true
    }
  }, [when])
}

export { useEffectOnceWhen }
